import * as React from "react"
import './skeleton-placeholder.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonPlaceholder = ({height}) => {
  return (
    <div className='skeleton-placeholder'>
      <Skeleton count={height} />
    </div>
  )
}

export default SkeletonPlaceholder