import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from '../../components/seo'
import './news.scss'
import SkeletonPlaceholder from "../../components/SkeletonPlaceholder/SkeletonPlaceholder"

const News = ({ pageContext }) => {
  const data = pageContext.content

  const compare = (a, b) => {
    if (a.node.order > b.node.order) {
      return -1;
    } else {
      return 1;
    }
    return 0;
  }

  return (
    <Layout>
      <Seo title="News" />

      <div className='root'>
        {
          data.filter(news => news.node.isCurrent).length ?
          <div className='current'>
            <div className="current__title">Recent</div>
            <div className="current__content">
              {data.filter(news => news.node.isCurrent).sort(compare).map((news, index) => (
                <div key={index} className='news'>
                  {/* 有link和pdf两种情况 */}
                  <a href={news.node.link ? news.node.link : news.node.pdf ? news.node.pdf.file.url : null} target='_blank'>
                    {news.node.featuredImage ? <img src={news.node.featuredImage.file.url} /> : <SkeletonPlaceholder height='10' />}
                    <div className='title'>{news.node.title}</div>
                    <div className='date'>{news.node.date}</div>
                  </a>
                </div>
              ))}
            </div>
        </div> :
        null
        }

        <div className='archive' style={{marginTop: '116px'}}>
          <div className="archive__title">Archive</div>
          <div className="archive__content">
            {data.filter(news => !news.node.isCurrent).sort(compare).map((news, index) => (
              <div key={index} className='news'>
                {/* 有link和pdf两种情况 */}
                <a href={news.node.link ? news.node.link : news.node.pdf ? news.node.pdf.file.url : null} target='_blank'>
                  {news.node.featuredImage ? <img src={news.node.featuredImage.file.url} /> : <SkeletonPlaceholder height='10' />}
                  <div className='title'>{news.node.title}</div>
                  <div className='date'>{news.node.date}</div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default News
